import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { formatDate, url } from '../../../utils/utils';
import * as Icon from 'react-feather';
// import './styles.scss'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PrimaryButton from '../../../Components/PrimaryButton';
import StyledInput from '../../../Components/StyledInput';
import Select from "react-select";

const SenderID = () => {
    const navigate = useNavigate()
    const ref = useRef();
    const [queryLimit, setqueryLimit] = useState(25);
    const [queryPages, setqueryPages] = useState(1);
    const [queryPagesCount, setqueryPagesCount] = useState(0);
    const [sidList, setSidList] = useState([]);
    const [users, setUsers] = useState([]);
    const [actionBtn, setActionBtn] = useState(false);
    const [actionBtnId, setActionBtnId] = useState(null)
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isEditId, setIsEditId] = useState('')

    const [route, setRoute] = useState('MKT')
    const [loading, setLoading] = useState(false)
    const UserToken = localStorage.getItem("token");
    const [userData, setUserData] = useState({})
    const [actionUser, setActionUser] = useState(null)

    const [err, setErr] = useState({
        name: "",
        srvErr: "",
    })
    const [formData, setFormData] = useState({
        sid: "",
        route: "",
        uid: "",
        status:""
    })

    const fetchSIDs = (uid) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(`${url}/api/admin/sids`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status === 'bad') {
                    navigate("/SignIn");
                    return;
                }
                setSidList(result.data)
                // console.log(result.data)
                setFilteredData(result.data)
            }).then(() => {
                fetch(`${url}/api/admin/users`, requestOptions)
                    .then(response => response.json())
                    .then(result => {

                        if (result.status === 'bad') {
                            navigate("/SignIn");
                            return;
                        }
                        setUsers(result.data);
                        // setFilteredData(result.data)
                    })
                    .catch(error => console.log('error', error));
            })
            .catch(error => console.log('error', error));

    }

    function formatDate(dateString) {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }


    useEffect(() => {
        const usrDT = localStorage.getItem('userData')

        if (usrDT) {
            const retrievedData = JSON.parse(usrDT);

            setUserData(retrievedData)
            fetchSIDs(retrievedData.uid)
            //   console.log(retrievedData)

        }

    }, [])

    useEffect(() => {
        // Filter the userArrayData based on the search query
        if (searchQuery === '') {
            setFilteredData(sidList);
        } else {
            setFilteredData(sidList.filter(sid =>
                // console.log(sid)
                (sid.sid && sid.sid.toLowerCase().includes(searchQuery.toLowerCase()))
                ||
                (sid.user && sid.user.toLowerCase().includes(searchQuery.toLowerCase()))
            ));
        }
    }, [searchQuery, sidList]);


    const downloadCsv = () => {
        // Get the table element
        const table = document.getElementById('transact-history');

        // Create an array to store rows
        const rows = [];

        // Iterate over each row in the table
        for (let i = 0; i < table.rows.length; i++) {
            const row = [];
            // Iterate over each cell in the row
            for (let j = 0; j < table.rows[i].cells.length; j++) {
                row.push(table.rows[i].cells[j].innerText);
            }
            // Join the row elements with a comma to create a CSV row
            rows.push(row.join(','));
        }

        // Join the rows with a newline character to create the CSV content
        const csvContent = rows.join('\n');

        // Create a Blob containing the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a link element
        const link = document.createElement('a');

        // Set the link's attributes
        link.href = window.URL.createObjectURL(blob);
        link.download = 'smarthive_transcation_history.csv';

        // Append the link to the document and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    const downloadExcel = () => {
        // Get the table element
        const table = document.getElementById('transact-history');

        // Create a worksheet
        const ws = XLSX.utils.table_to_sheet(table);

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Create a Blob containing the Excel file
        const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });

        // Save the Blob as a file
        saveAs(blob, 'smarthive_transcation_history.xlsx');
    };

    const createSID = (e) => {
        setLoading(true)
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var raw = JSON.stringify({
            sid: formData.sid,
            route: formData.route,
            status: formData.status
        });

        // console.log({ formData })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        fetch(`${url}/api/admin/createSid?uid=${formData.uid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoading(false)
                if (result.error) {
                    // console.log(result)

                    setErr({
                        srvErr: result.message
                    })
                    return
                }
                formData.sid = ""
                formData.route = ""
                formData.status = ""
                formData.uid = ""
                setErr({})

                setModal(false)
            })
            .catch(error => {
                // console.log('error', error)
                setErr({
                    srvErr: error.message
                })

                setLoading(false)
            })
            .finally(() => fetchSIDs(userData.uid));
    }

    const updateSID = (e) => {
        setLoading(true)
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var raw = JSON.stringify({
            sid: formData.sid,
            route: formData.route,
            status: formData.status
        });

        // console.log({ formData })

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        fetch(`${url}/api/admin/updateSid?uid=${formData.uid}&id=${isEditId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoading(false)
                if (result.error) {
                    // console.log(result)

                    setErr({
                        srvErr: result.message
                    })
                    return
                }
                formData.sid = ""
                formData.route = ""
                formData.status = ""
                formData.uid = ""
                setErr({})

                setModal(false)
            })
            .catch(error => {
                // console.log('error', error)
                setErr({
                    srvErr: error.message
                })

                setLoading(false)
            })
            .finally(() => fetchSIDs(userData.uid));
    }

    const handleRouteChange = (selectedOption) => {
        // const val = selectedOption.target.value
        // setRoute(val);
        // console.log(selectedOption.value)
        setFormData({ ...formData, route: selectedOption.value });

    };

    const handleUserChange = (selectedOption) => {
        setFormData({ ...formData, uid: selectedOption.value });
    };

    const handleStatusChange = (selectedOption) => {
        setFormData({ ...formData, status: selectedOption.value });
    };

    const enableSID = (item) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var raw = JSON.stringify({
            _id: item._id,
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/api/admin/sid/activate?uid=${item.uid}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status === 'bad') {
                    navigate("/SignIn");
                    return;
                }
                fetchSIDs();
                // console.log(result)
            })
            .catch(error => console.log('error', error));
    }

    const disableSID = (item) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var raw = JSON.stringify({
            _id: item._id,
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/api/admin/sid/deactivate?uid=${item.uid}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status === 'bad') {
                    navigate("/SignIn");
                    return;
                }
                fetchSIDs();
                // console.log(result)
            })
            .catch(error => console.log('error', error));
    }

    // Function to handle clicks outside the button area
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setActionBtn(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const userActionOption = (item) => {
        setActionBtnId(item._id);
        setActionBtn(true);
        setActionUser(item)
    };

    const editSid = (item) => {
        setIsEdit(true)
        setIsEditId(item._id)
        setModal(true)
        setFormData({ uid: item.uid, sid: item.sid, status: item.status, route: item.route });
        // console.log(item.status)
        // formData.uid = item.uid
        // formData.sid = item.sid

    }

    return (
        <>
            {modal &&
                <div className='fixed w-screen h-screen z-[20]'>
                    <div className='backdrop-blur fixed w-full h-full bg-black/20' />
                    <div className=' w-full h-full flex relative flex-col items-center justify-center'>
                        <div className='bg-white p-6 w-96 relative'>
                            <p className='font-bold'>Add Sender ID</p>
                            <div className='absolute top-0 right-0 m-6 bg-[var(--primary-border-grey)] rounded-full p-1' onClick={() => {
                                setModal(false);
                                setLoading(false)
                            }}>
                                <Icon.X size={15} color='var(--primary-grey)' />
                            </div>
                            <form onSubmit={isEdit?  updateSID : createSID}>
                                <StyledInput label={'Name'} value={formData.sid} handleChange={(e) => setFormData({ ...formData, sid: e.target.value })} required formError={err.name} />

                                <p className='text-[var(--primary-grey)]'>Route</p>
                                {/* <select className='w-full border rounded-lg mt-2 p-2 text-[var(--primary-grey)]' id="yourSelector" value={route} onChange={handleRouteChange}>
                                    <option value="MKT">Marketing</option>
                                    <option value="TRX">Transaction</option>
                                    <option value="INTL">International</option>
                                </select> */}
                                <Select
                                    className="select-country"
                                    onChange={handleRouteChange}
                                    options={[
                                        { value: "MKT", label: "Marketing" },
                                        { value: "TRX", label: "Transaction" },
                                        { value: "INTL", label: "International" },
                                    ]}
                                    value={formData.route ? { value: formData.route, label: formData.route === 'MKT' ? 'Marketing' : formData.route === 'TRX' ? 'Transaction' : 'International' } : null}
                                    placeholder="Select Route"
                                />


                                <p className='text-[var(--primary-grey)] mt-2 mb-2'>User</p>
                                <Select
                                    className="select-country"
                                    onChange={handleUserChange}
                                    options={users.map(user => ({ value: user.uid, label: user.email }))}
                                    value={formData.uid ? users.find(user => user.uid === formData.uid) && { value: formData.uid, label: users.find(user => user.uid === formData.uid).email } : null}
                                    placeholder="Select User"
                                />


                                <p className='text-[var(--primary-grey)] mt-2 mb-2'>Status</p>
                                <Select
                                    className="select-country"
                                    onChange={handleStatusChange}
                                    value={formData.status ? { value: formData.status, label: formData.status === 'ACTIVE' ? 'Active' : 'In Active' } : null}
                                    options={[
                                        { value: "ACTIVE", label: "Active" },
                                        { value: "IN-ACTIVE", label: "In Active" },
                                    ]}
                                    placeholder="Status"
                                />

                                <div className='mt-6'>
                                    <PrimaryButton text={isEdit? 'Update': 'Submit'} color={'var(--primary-color)'} primary type="submit" loading={loading} />

                                </div>

                                <p style={{ color: 'red', textAlign: 'center', fontWeight: 600 }}>{err.srvErr}</p>
                            </form>

                        </div>
                    </div>
                </div>
            }
            <div className='topup-main-container'>
                <div className='head-line flex'>
                    <p className='head-1'>Manage Sender IDs</p>
                    <p className='head-2'>Dashboard</p>
                    <p className='head-2'>{'>'}</p>

                    <p className='head-2'>Admin</p>
                    <p className='head-2'>{'>'}</p>

                    <p className='head-2'>Sender IDs</p>

                </div>

                <div className='topup-content-container history'>

                    <div className='flex justify-between'>
                        <div className='flex'>
                            <p>Download</p>

                            <p className='each_option' onClick={() => downloadCsv()}>CSV</p>
                            <p className='each_option' onClick={() => downloadExcel()}>Excel</p>
                            {/* <p className='each_option' onClick={()=>  window.print()}>Print</p> */}

                        </div>

                        <PrimaryButton text={'Add Sender ID'}
                            // icon={'Plus'}
                            color={'var(--primary-color)'}
                            primary
                            handleClick={() => setModal(true)}
                        />


                    </div>
                    <div>
                        <input
                            type="text"
                            className='p-2 border-green-950 border rounded-sm'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search..."
                        />
                    </div>
                    <div className='comp-container-sid'>
                        <div className='comp-container-inner'>
                            <div className='table-container'>
                                <table id="transact-history">
                                    <thead>
                                        <tr>
                                            <th class="selector">
                                                <input type="checkbox" id="selectRow1" class="select-row" />
                                            </th>
                                            <th>S/N</th>
                                            <th>Sender ID</th>
                                            <th>Account</th>
                                            <th>Status</th>
                                            <th>Route</th>
                                            <th>Date Created</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData && filteredData.length > 0 && filteredData.slice().reverse().map((item, index) => (
                                            <tr key={index}>
                                                <td class="selector">
                                                    <input type="checkbox" id="selectRow1" class="select-row" />
                                                </td>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>{item.sid}</td>
                                                <td>{item.user}</td>
                                                <td><p className={item.status === 'ACTIVE'? 'bg-[green]': 'bg-[red]'}>{item.status}</p></td>
                                                <td>{item.route}</td>
                                                <td>{formatDate(item.requested)}</td>

                                                <td ref={ref}>
                                                    <button onClick={() => userActionOption(item)} className='bg-[green] text-white p-2 rounded-md'>Option</button>
                                                    {actionBtn && actionBtnId === item._id &&
                                                        <div className='absolute bg-white right-3 rounded-md shadow-black drop-shadow-2xl mt-2' ref={ref}>
                                                            <ul className='p-0'>
                                                                <li onClick={() => editSid(item)} className='px-10 py-2 hover:bg-[#1a4b1a] hover:text-white cursor-pointer'>Edit</li>
                                                                {item.status === 'ACTIVE' ?
                                                                    <button onClick={() => disableSID(item)} className='bg-[red] text-white px-10 py-2 hover:bg-[#1a4b1a] hover:text-white cursor-pointer'>Disable</button>
                                                                    :
                                                                    <button onClick={() => enableSID(item)} className='bg-[green] text-white px-10 py-2 hover:bg-[#1a4b1a] hover:text-white cursor-pointer'>Enable</button>
                                                                }
                                                                {/* <li className='px-10 py-2 hover:bg-[#1a4b1a] hover:text-white cursor-pointer' onClick={() => settopupmodal(true)}>Top up</li> */}
                                                                {/* <li className='px-10 py-2 hover:bg-[#1a4b1a] hover:text-white cursor-pointer' onClick={() => setreportmodal(true)}>Generate Report</li> */}
                                                            </ul>
                                                        </div>
                                                    }
                                                </td>
                                                {/* <td>{item.status === 'ACTIVE' ?
                                                    <button onClick={() => disableSID(item)} className='bg-[red] text-white p-2 rounded-md'>Disable</button>
                                                    :
                                                    <button onClick={() => enableSID(item)} className='bg-[green] text-white p-2 rounded-md'>Enable</button>
                                                }</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className='flex bottom-qry-container'>

                                <div className='flex'>
                                    <div className='selector-container'>
                                        <select id="yourSelector" value={queryLimit}>
                                            {/* <option value="">Select...</option> */}
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            {/* Add more options as needed */}
                                        </select>

                                    </div>
                                    {/* <span> {queryPages} - {queryPagesCount} of {queryPagesCount}</span> */}

                                </div>

                                <div className='page-nav-container'>
                                    <span>{'<'}</span>
                                    <span className='count'>{queryPages}</span>
                                    <span>{'>'}</span>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SenderID
import React, { useState } from 'react'
import "./styles.scss";
import * as Icon from 'react-feather';

const StyledInput = ({ label, handleChange, formError, password, type, ...otherProps }) => {
    const [passtoggle, setPasstoggle] = useState(false);

    const hidePass = () => {
        setPasstoggle(!passtoggle);
        console.log(passtoggle)
    }

    return (
        <div className="input-group">
            {label && (
                <label>
                    {label}
                </label>
            )}
            <div className='input'>
                <input
                    {...otherProps}
                    type={password && !passtoggle ? 'password': type? type : 'text' }
                    onChange={handleChange}
                    autoComplete='false'
                />

                {password && (
                    <div className='visibility-btn rounded-full' onClick={hidePass} >
                    <div className='toggle-Icn'>
                        <div>
                           {passtoggle? (
                             <Icon.EyeOff size={12} color='white'/>
                           ):
                           (
                            <Icon.Eye size={12} color='white'/>
                           )}
                        </div>

                        {/* <i class={passtoggle ? "fa1 fa-eye-slash" : "fa1 fa-eye-slash active"}></i> */}
                    </div>
                </div>
                )}
            </div>
            {formError && (
                <span className="error">{formError}</span>
            )}
        </div>
    )
}

export default StyledInput
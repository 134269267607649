import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import PrimaryButton from '../../../Components/PrimaryButton';
import StyledInput from '../../../Components/StyledInput';
import Select from "react-select";
import * as XLSX from 'xlsx';
import { formatCurrency, formatDate, url } from '../../../utils/utils';
import * as Icon from 'react-feather';
import { generate } from 'shortid';
import { ChevronDown, ChevronRight } from "react-feather";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useCheckAuth } from '../../../utils/checkAuth';

const Users = () => {
    const navigate = useNavigate()
    const [queryLimit, setqueryLimit] = useState(25);
    const [queryPages, setqueryPages] = useState(1);
    const [queryPagesCount, setqueryPagesCount] = useState(0);
    const [userArrayData, setuserArrayData] = useState([]);
    const [actionBtn, setActionBtn] = useState(false);
    const [actionBtnId, setActionBtnId] = useState(null);
    const [actionUser, setActionUser] = useState(null)
    const [reportmodal, setreportmodal] = useState(false)
    const [topupmodal, settopupmodal] = useState(false)
    const [tpAmount, setTPAmount] = useState(0)
    const [tpAmountErr, setTPAmountErr] = useState('')
    const [loading, setLoading] = useState(false)
    const UserToken = localStorage.getItem("token");
    const [userData, setUserData] = useState({})
    const ref = useRef();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [openDatePick, setOpenDatePick] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [route, setRoute] = useState('MKT');
    const [GeneratedReportData, setGeneratedReportData] = useState([]);
    const [showReportData, setShowReportData] = useState(false);
    const checkAuth = useCheckAuth();

    useEffect(() => {
        checkAuth().then(() => {
            const usrDT = localStorage.getItem('userData')

            if (usrDT) {
                const retrievedData = JSON.parse(usrDT);

                setUserData(retrievedData)
            }
        })

    }, [])

    const downloadCsv = () => {
        // Get the table element
        const table = document.getElementById('transact-history');

        // Create an array to store rows
        const rows = [];

        // Iterate over each row in the table
        for (let i = 0; i < table.rows.length; i++) {
            const row = [];
            // Iterate over each cell in the row
            for (let j = 0; j < table.rows[i].cells.length; j++) {
                row.push(table.rows[i].cells[j].innerText);
            }
            // Join the row elements with a comma to create a CSV row
            rows.push(row.join(','));
        }

        // Join the rows with a newline character to create the CSV content
        const csvContent = rows.join('\n');

        // Create a Blob containing the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a link element
        const link = document.createElement('a');

        // Set the link's attributes
        link.href = window.URL.createObjectURL(blob);
        link.download = 'smarthive_transcation_history.csv';

        // Append the link to the document and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    const downloadExcel = () => {
        // Get the table element
        const table = document.getElementById('transact-history');

        // Create a worksheet
        const ws = XLSX.utils.table_to_sheet(table);

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Create a Blob containing the Excel file
        const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });

        // Save the Blob as a file
        saveAs(blob, 'smarthive_transcation_history.xlsx');
    };

    const getAllUsers = () =>{
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${url}/api/admin/users`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status === 'bad') {
                    navigate("/SignIn");
                    return;
                }

                const data = result.data;

                setuserArrayData(data);
                setFilteredData(data);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${url}/api/admin/users`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status === 'bad') {
                    navigate("/SignIn");
                    return;
                }

                const data = result.data;

                setuserArrayData(data);
                setFilteredData(data);
            })
            .catch(error => console.error(error));
    }, [UserToken, navigate]);

    useEffect(() => {
        // Filter the userArrayData based on the search query
        if (searchQuery === '') {
            setFilteredData(userArrayData);
        } else {
            setFilteredData(userArrayData.filter(user =>
                user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
                user.email.toLowerCase().includes(searchQuery.toLowerCase())
            ));
        }
    }, [searchQuery, userArrayData]);

    const userActionOption = (item) => {
        setActionBtnId(item.uid);
        setActionBtn(true);
        setActionUser(item)
    };

    // Function to handle clicks outside the button area
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setActionBtn(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleRouteChange = (event) => {
        const val = event.target.value
        setRoute(val);
    };

    const generateReport = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        const raw = JSON.stringify({
            startDate: startDate,
            endDate: endDate,
            route: route
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${url}/api/admin/report?uid=${actionBtnId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                const data = result.data
                data.sort((a, b) => new Date(a.date) - new Date(b.date));
                console.log(data)
                setGeneratedReportData(data)
                setShowReportData(true);

            })
            .catch((error) => console.error(error));
    }

    const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);

        // console.log(ranges.selection.startDate)
        setStartDate(ranges.selection.startDate)
        setEndDate(ranges.selection.endDate)

    };


    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const creditUser = () => {
        setLoading(true)
        if(tpAmount < 100) {
            setTPAmountErr('Value cannot be < 100');
            setLoading(false)

            return
        }
        const myHeaders = new Headers();
        myHeaders.append("api-auth-key", "mobile_tech_solution_1234567890987654321");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        // console.log(tpAmount)

        const raw = JSON.stringify({
            def_amount: parseInt(tpAmount)
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${url}/api/admin/topup?adminUid=${userData.uid}&uid=${actionBtnId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if(result.error){
                    alert('An error occured. Please try again');
                    setLoading(false)

                    return
                }

                settopupmodal(false);
                getAllUsers();
                setLoading(false);
                // console.log(result)
            })
            .catch((error) => console.error(error));
    }

    return (
        <>
            {showReportData && GeneratedReportData && GeneratedReportData.length > 0 &&
                <div className='w-full h-full fixed z-50 top-0 left-0  mt-20 flex items-center justify-center'>
                    <div className='bg-white p-6 w-[50%] h-[50%] overflow-scroll relative'>
                        <div className='absolute top-0 right-0 m-6 bg-[var(--primary-border-grey)] rounded-full p-1' onClick={() => {
                            setShowReportData(false);
                        }}>
                            <Icon.X size={15} color='var(--primary-grey)' />
                        </div>
                        <table id="transact-history" className='w-full h-full'>
                            <thead >
                                <tr className='p-10 text-left'>
                                    <th>S/N</th>
                                    <th>Date</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {GeneratedReportData && GeneratedReportData.length > 0 && GeneratedReportData.map((item, index) => (
                                    <tr key={index}>
                                        {/* <td class="selector">
                    <input type="checkbox" id="selectRow1" class="select-row" />
                </td> */}
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>{item.date}</td>
                                        <td>{item.count}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            {topupmodal &&
                <div className='fixed w-screen h-screen z-[20]'>
                    <div className='backdrop-blur fixed w-full h-full bg-black/20' />

                    <div className=' w-full h-full flex relative flex-col items-center justify-center'>
                        <div className='bg-white p-6 w-96 relative'>
                            <p className='font-bold'>Top Up User's Account</p>
                            <p>{actionUser.email}</p>

                            <div className='absolute top-0 right-0 m-6 bg-[var(--primary-border-grey)] rounded-full p-1' onClick={() => {
                                settopupmodal(false);
                                setLoading(false);
            setTPAmountErr('');

                            }}>
                                <Icon.X size={15} color='var(--primary-grey)' />
                            </div>

                            <StyledInput label={'Amount'} type={'number'} handleChange={(e) => setTPAmount(e.target.value)} formError={tpAmountErr}/>

                            <div className='mt-6'>
                                <PrimaryButton text={'Top Up'} color={'var(--primary-color)'} primary handleClick={() => creditUser()} loading={loading} />

                            </div>
                        </div>
                    </div>

                </div>
            }
            {reportmodal &&
                <div className='fixed w-screen h-screen z-[20]'>
                    <div className='backdrop-blur fixed w-full h-full bg-black/20' />
                    <div className=' w-full h-full flex relative flex-col items-center justify-center'>
                        <div className='bg-white p-6 w-96 relative'>
                            <p className='font-bold'>Generate Report</p>
                            <div className='absolute top-0 right-0 m-6 bg-[var(--primary-border-grey)] rounded-full p-1' onClick={() => {
                                setreportmodal(false);
                                setLoading(false)
                            }}>
                                <Icon.X size={15} color='var(--primary-grey)' />
                            </div>
                            <div>

                                <p className='text-[var(--primary-grey)]'>Route</p>
                                <select className='w-full border rounded-lg mt-2 p-2 text-[var(--primary-grey)]' id="yourSelector" value={route} onChange={handleRouteChange}>
                                    <option value="MKT">Marketing</option>
                                    <option value="TRX">Transaction</option>
                                    <option value="INTL">International</option>
                                </select>

                                <div className="picker-cont mt-6">
                                    <div className="picker">
                                        {openDatePick ? <div className="dt-rg">
                                            <DateRange
                                                ranges={dateRange}
                                                onChange={handleSelect}
                                            />
                                        </div> :
                                            <div className="datepicker-btn flex" onClick={() => setOpenDatePick(true)}>
                                                <div>
                                                    {dateRange[0].startDate ? (
                                                        // Display the selected date range
                                                        `${dateRange[0].startDate.toDateString()} - ${dateRange[0].endDate.toDateString()}`
                                                    ) : (
                                                        // Display placeholder text
                                                        'Select date range'
                                                    )}
                                                </div>
                                                <ChevronDown size={14} />
                                            </div>

                                        }

                                    </div>
                                </div>

                                <div className='mt-6'>
                                    <PrimaryButton text={'Generate'} color={'var(--primary-color)'} primary handleClick={() => generateReport()} loading={loading} />

                                </div>

                                {/* <p style={{ color: 'red', textAlign: 'center', fontWeight: 600 }}>{err.srvErr}</p> */}
                            </div>

                        </div>
                    </div>
                </div>
            }
            <div className='topup-main-container'>
                <div className='head-line flex'>
                    <p className='head-1'>Manage Users</p>
                    {/* <p className='head-2'>Dashboard</p> */}
                    <p className='head-2'>{'>'}</p>

                    <p className='head-2'>Admin</p>
                    <p className='head-2'>{'>'}</p>

                    <p className='head-2'>Users</p>

                </div>

                <div className='topup-content-container history'>

                    <div className='flex justify-between'>
                        <div className='flex'>
                            <p>Download</p>

                            <p className='each_option' onClick={() => downloadCsv()}>CSV</p>
                            <p className='each_option' onClick={() => downloadExcel()}>Excel</p>
                            {/* <p className='each_option' onClick={()=>  window.print()}>Print</p> */}

                        </div>

                        <div>
                            <input
                                type="text"
                                className='p-2 border-green-950 border rounded-sm'
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search for a user..."
                            />
                        </div>


                    </div>
                    <div className='comp-container-sid'>
                        <div className='comp-container-inner'>
                            <div className='table-container'>
                                <table id="transact-history">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Email</th>
                                            <th>Username</th>
                                            <th>Role</th>
                                            <th>Balance</th>
                                            <th>MKT</th>
                                            <th>TRX</th>
                                            <th>INTL</th>
                                            <th>Status</th>
                                            <th>Created</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData && filteredData.length > 0 && filteredData.slice().reverse().map((item, index) => (
                                            <tr key={index}>
                                                {/* <td class="selector">
                                                <input type="checkbox" id="selectRow1" class="select-row" />
                                            </td> */}
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>{item.email}</td>
                                                <td>{item.username}</td>
                                                <td>{item.role}</td>
                                                <td>{formatCurrency(item.sms_balance)}</td>
                                                <td>{formatCurrency(item.charges_open)}</td>
                                                <td>{formatCurrency(item.charges_coop)}</td>
                                                <td>{formatCurrency(item.charges_int)}</td>
                                                {item.active ?
                                                    <td className='text-green-950'>ACTIVE</td>
                                                    :
                                                    <td className='text-red-900'>INACTIVE</td>
                                                }
                                                <td>{item.createdAt}</td>
                                                <td ref={ref}>
                                                    <button onClick={() => userActionOption(item)} className='bg-[green] text-white p-2 rounded-md'>Option</button>
                                                    {actionBtn && actionBtnId === item.uid &&
                                                        <div className='absolute bg-white right-3 rounded-md shadow-black drop-shadow-2xl mt-2' ref={ref}>
                                                            <ul className='p-0'>
                                                                <li className='px-10 py-2 hover:bg-[#1a4b1a] hover:text-white cursor-pointer'>Edit</li>
                                                                <li className='px-10 py-2 hover:bg-[#1a4b1a] hover:text-white cursor-pointer'>Disable</li>
                                                                <li className='px-10 py-2 hover:bg-[#1a4b1a] hover:text-white cursor-pointer' onClick={() => settopupmodal(true)}>Top up</li>
                                                                <li className='px-10 py-2 hover:bg-[#1a4b1a] hover:text-white cursor-pointer' onClick={() => setreportmodal(true)}>Generate Report</li>
                                                            </ul>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className='flex bottom-qry-container'>

                                <div className='flex'>
                                    <div className='selector-container'>
                                        <select id="yourSelector" value={queryLimit}>
                                            {/* <option value="">Select...</option> */}
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            {/* Add more options as needed */}
                                        </select>

                                    </div>
                                    {/* <span> {queryPages} - {queryPagesCount} of {queryPagesCount}</span> */}

                                </div>

                                <div className='page-nav-container'>
                                    <span>{'<'}</span>
                                    <span className='count'>{queryPages}</span>
                                    <span>{'>'}</span>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default Users
import React from 'react';
import "./styles.scss";
import companyLogo from "../../../assets/companylogo.png";
import PrimaryButton from "../../../Components/PrimaryButton"
import illus from "../../../assets/Connected-world-bro.png";

const Sidebar = () => {
  return (
    <div className="company-details">
      <div className="company-details-inner">
        <img src={companyLogo} alt="Company Logo" className="logo" />


        <div className='centered-img'>
          <img src={illus} alt="Company Logo" className="logo" />
        </div>

        <div className="maintext">
          <p className="paragraph">Documentation</p>

          <p className="content">
            Smarthive Tech SMS Communication Web Application empowers users to execute efficient and targeted SMS communication strategies.
            {/* With its user-friendly interface and comprehensive set of features, it serves as an invaluable tool for businesses and individuals seeking a reliable and transparent SMS communication platform. */}
          </p>



        </div>

        <div className='moreInfo'>
        <PrimaryButton
          text={'Check it out'}
          icon={'ChevronRight'}
          linkTo={'https://smarthive-tech-solution.gitbook.io/smarthive-tech-solution-api/'}
          color={'var(--primary-color)'}
          variant
        />
      </div>
      </div>

      
    </div>
  );
}

export default Sidebar
